<template>
  <div id="pdf-content">
    <div class="container-header">
      <div style="display:none;">
        {{ styleData }}
        {{ styleSubData }}
      </div>
      <!-- <h1>
        نموذج
        <span class="color">تقرير التقييم</span>
      </h1>
 -->
      <div class="sub-header d-flex justify-content-between align-items-center">
        <div
          v-if="data.customer.show_logo === '1'"
          class="image-facilty"
        >
          <img :src="data.customer.cs_logo">
        </div>
        <div>
          <h2>{{ data.customer.reportName || '' }}</h2>
        </div>
        <!-- <div>
          <h2>الرقم المرجعي للتقرير</h2>
          <h3>رقم الهاتف:</h3>
          <h3>المملكة العربية السعودية،</h3>
        </div>
        <div>
          <h2>تاريخ إصدار التقرير</h2>
          <h3>رمز إيداع التقرير:</h3>
        </div> -->
        <div class="image-facilty">
          <img :src="data.facility.logo">
        </div>
      </div>
    </div>
    <div class="divider" />
    <table class="colspan">
      <thead :style="styleData">
        <tr>
          <th colspan="4">
            <div class="header">
              <div class="header-text">
                هوية المقيّم المعتمد
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td rowspan="2">
            اسم المقيم المعتمد
          </td><td rowspan="2">
            {{ data.facility.resident_name || '' }}
          </td><td>رقم الترخيص</td><td>{{ data.facility.license_number || '' }}</td>
        </tr>
        <tr><td>تاريخ الترخيص</td><td>{{ data.facility.license_date || '' }}</td></tr>
      </tbody>
    </table>
    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                نطاق العمل
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            إسم العميل
          </td>
          <td>{{ data.customer.cs_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            إسم مستخدم التقرير
          </td>
          <td>{{ data.trans_report_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            الغرض من التقييم
          </td>
          <td>{{ data.evaluationpurpose.name || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            أساس القيمة
          </td>
          <td>محمد أحمد</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ التقييم
          </td>
          <td>{{ data.trans_evaluation_date || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ المعاينة
          </td>
          <td>{{ data.trans_inspection_date || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            الرقم المرجعي
          </td>
          <td>{{ data.trans_reference_number || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ التكليف
          </td>
          <td>{{ data.trans_assignment_date || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            نوع التقرير
          </td>
          <td>
            {{ data.trans_Report_type || '' }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- الأصل محل التقييم -->
    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="5">
            <div class="header">
              <div class="header-text">
                الأصل محل التقييم
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            :style="styleSubData"
            class="field"
          >
            نوع العقار
          </td>
          <td
            v-for="type in data.propTypeList"
            :key="type.id"
          >
            <v-checkbox
              v-model="data.property_type_id"
              :value="type.id"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  {{ type.name || '' }}
                </div>
              </template>
            </v-checkbox>
          </td>
        </tr>
        <tr>
          <td
            :style="styleSubData"
            class="field"
          >
            حالة العقار
          </td>
          <td
            v-for="type in data.transConstructionList"
            :key="type.id"
          >
            <v-checkbox
              v-model="data.trans_construction_condition"
              :value="type.id"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  {{ type.name || '' }}
                </div>
              </template>
            </v-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- تفاصيل موقع العقار-->
    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                تفاصيل موقع العقار
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المنطقة
          </td>
          <td>{{ data.region.name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المدينة
          </td>
          <td>{{ data.city.name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم الحي
          </td>
          <td>{{ data.neighborhood.name || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم الشارع
          </td>
          <td>{{ data.trans_street_name|| '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المخطط
          </td>
          <td>{{ data.residential_plan_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم المخطط
          </td>
          <td>{{ data.residential_plan_no || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم البلوك
          </td>
          <td>{{ data.trans_Albulk_num || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم القطعة
          </td>
          <td>{{ data.trans_part_num || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            استخدام العقار
          </td>
          <td>{{ (data.propertyrating && data.propertyrating.name) || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المالك
          </td>
          <td>{{ data.trans_owner_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم المالك
          </td>
          <td>{{ data.trans_owner_phone || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم الصك
          </td>
          <td>{{ data.trans_instrument_num|| '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ الصك
          </td>
          <td>{{ data.trans_instrument_date|| '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم رخصة البناء
          </td>
          <td>{{ data.trans_building_permit_number|| '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ رخصة البناء
          </td>
          <td>{{ new Date(data.trans_building_permit_date).toLocaleDateString('en-GB').split('/').reverse().join('-') }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم محضر التجزئة
          </td>
          <td>
            {{ data.trans_retail_record_num || '' }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="html2pdf__page-break" />
    <table class="first">
      <tbody class="has-fields">
        <tr class="collapse">
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المدينة
          </td>
          <td>{{ data.city.name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم الحي
          </td>
          <td>{{ data.neighborhood.name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            اسم الشارع
          </td>
          <td>{{ data.trans_street_name|| '' }}</td>
        </tr>
        <tr class="collapse">
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المخطط
          </td>
          <td>{{ data.residential_plan_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم المخطط
          </td>
          <td>{{ data.residential_plan_no || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم البلوك
          </td>
          <td>{{ data.trans_Albulk_num || '' }}</td>
        </tr>
        <tr class="collapse">
          <td
            class="field"
            :style="styleSubData"
          >
            رقم القطعة
          </td>
          <td>{{ data.trans_part_num || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            استخدام العقار
          </td>
          <td>{{ data.propertyrating.name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            احداثيات الموقع
          </td>
          <td class="collapse">
            {{ data.latitude || '' }}
            {{ data.longitude || '' }}
          </td>
        </tr>
        <tr class="collapse">
          <td
            class="field"
            :style="styleSubData"
          >
            اسم المالك
          </td>
          <td>{{ data.trans_owner_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم الصك
          </td>
          <td>{{ data.trans_instrument_num|| '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ الصك
          </td>
          <td>{{ data.trans_instrument_date|| '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم رخصة البناء
          </td>
          <td>{{ data.trans_building_permit_number|| '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ رخصة البناء
          </td>
          <td>{{ new Date(data.trans_building_permit_date).toLocaleDateString('en-GB').split('/').reverse().join('-') }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            عمر البناء
          </td>
          <td>{{ data.trans_construction_age || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم محضر التجزئة
          </td>
          <td>{{ data.trans_retail_record_num || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            حالة العقار
          </td>
          <td>مثال</td>
          <td
            class="field"
            :style="styleSubData"
          >
            حالة الإشغال
          </td>
          <td>مثال</td>
        </tr>
      </tbody>
    </table> -->
    <!--بيانات المبنى-->
    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                بيانات المبنى
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            مساحة الأرض
          </td>
          <td>{{ data.land_area || '' }}</td>

          <td
            class="field"
            :style="styleSubData"
          >
            المسطحات المبنية
          </td>
          <td>{{ data.additional_component_4 || '' }}</td>

          <td
            class="field"
            :style="styleSubData"
          >
            عدد الأدوار
          </td>
          <td>{{ data.prop_floor || '' }}</td>
        </tr>
      </tbody>
    </table>
    <!--بيانات الانشاء-->
    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                بيانات الانشاء
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ بداية الانشاء
          </td>
          <td>{{ data.additional_component_3 }}</td>

          <td
            class="field"
            :style="styleSubData"
          >
            المرحلة الرئيسية
          </td>
          <td>{{ data.additional_component_1 || '' }}</td>

          <td
            class="field"
            :style="styleSubData"
          >
            المرحلة الفرعية
          </td>
          <td>{{ data.additional_component_2 || '' }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="html2pdf__page-break"
      :data-number="['1','من',totalPages].join(' ')"
    />
    <table class="table-fixed first">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                تقييم الإنجاز
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            البيان
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            تفاصيل المرحلة
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            النسبة الى التكلفة
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            التكلفة
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            نسبة الإنجاز
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            قيمة الإنجاز
          </td>
        </tr>
        <tr
          v-for="(p,index) in data.achievement.stages"
          :key="p.id"
        >
          <td>
            م{{ index + 1 }}
          </td>
          <td>
            {{ p.details }}
          </td>
          <td>
            {{ p.pc_to_cost }}
          </td>
          <td>
            {{ p.cost }}
          </td>
          <td>
            {{ p.pc_of_completion }}
          </td>
          <td>
            {{ p.achievement_value }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table-fixed">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header">
              <div class="header-text">
                النتائج
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            قيمة العقار الحالية
          </td>
          <td>
            {{ data.achievement.prop_current_price }}
          </td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            المبلغ المتبقي لاتمام البناء
          </td>
          <td />
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            مدة الإنجاز المتبقية
          </td>
          <td>
            {{ data.additional_component_value_1 || '' }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- وصف العقار-->
    <table>
      <thead class="diff">
        <tr>
          <th colspan="4">
            <div class="header ">
              <div class="header-text">
                وصف العقار
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            <p style="min-height:100px">
              {{ data.property_notes || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- حدود وأطوال العقار -->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                حدود وأطوال العقار
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            الجهة
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            طول ضلع الأرض
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            الوصف
          </td>
          <td
            class="field"
            :style="styleSubData"
          >
            الواجهات
          </td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            شمالي
          </td>
          <td>{{ data.tall_northern || '' }}</td>
          <td>{{ data.northern_border || '' }}</td>
          <td>{{ data.north_facade || '' }}</td>
          <!-- <td>مساحة الأرض</td>
          <td>{{ data.land_area || '' }}</td> -->
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            غربي
          </td>
          <td>{{ data.tall_western || '' }}</td>
          <td>{{ data.western_border || '' }}</td>
          <td>{{ data.western_facade || '' }}</td>
          <!-- <td
            rowspan="1"
            style="padding:0px;"
          >
            مساحة المباني
          </td>

          <td style="padding:0px;">
            مثال
          </td> -->
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            جنوبي
          </td>
          <td>{{ data.tall_northern || '' }}</td>
          <td>{{ data.southern_border || '' }}</td>
          <td>{{ data.south_facade || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            شرقي
          </td>
          <td>{{ data.tall_eastern || '' }}</td>
          <td>{{ data.eastern_border || '' }}</td>
          <td>{{ data.north_facade || '' }}</td>
        </tr>
      </tbody>
    </table>
    <!--معلومة رخصة البناء-->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                معلومات رخصة البناء
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            style="width:25%;"
            class="field"
            :style="styleSubData"
          >
            هل العقار على الطبيعة
            مطابق لرخصة البناء
          </td>
          <td style="width:25%;">
            <v-checkbox
              v-model="data.conforms_building_permit"
              value="1"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  نعم
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="data.conforms_building_permit"
              value="0"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  لا
                </div>
              </template>
            </v-checkbox>
          </td>
          <td
            style="width:25%;"
            class="field"
            :style="styleSubData"
          >
            حدود المعاينة
          </td>
          <td style="width:25%;">
            <v-checkbox
              v-model="data.internal_preview"
              value="1"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  من داخل العقار
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="data.internal_preview"
              value="0"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  من خارج العقار
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="data.internal_preview"
              value="1"
              hide-details
            >
              <template v-slot:label>
                <div class="checkbox-label">
                  من داخل وخارج العقار
                </div>
              </template>
            </v-checkbox>
          </td>
        </tr>
        <tr>
          <td
            colspan="4"
            class="field"
            :style="styleSubData"
          >
            في حال عدم مطابقة العقار لرخصة البناء، الرجاء توضيح المخالفات أو أي مالحظات:
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <p style="min-height:50px;">
              {{ data.review_note || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <!--المشاركون في إعداد التقرير-->
    <div
      class="html2pdf__page-break"
      :data-number="['5','من',totalPages].join(' ')"
    />
    <table class="first">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                المشاركون في إعداد التقرير
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <v-container>
          <v-row>
            <v-col
              v-for="(member,index) in data.members"
              :key="index"
              cols="12"
              sm="4"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="td field"
                  :style="styleSubData"
                >
                  الاسم
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="td"
                >
                  {{ member.name || '' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="td field"
                  :style="styleSubData"
                >
                  المنصف
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="td"
                >
                  {{ member.type }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="td field"
                  :style="styleSubData"
                >
                  الرقم
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="td"
                >
                  {{ member.number }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="td field"
                  :style="styleSubData"
                >
                  التوقيع
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="td"
                >
                  {{ member.s }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <!-- <tr>
          <td>الإسم</td>
          <td>1</td>
          <td>الإسم</td>
          <td>1</td>
        </tr>
        <tr>
          <td>
            المسمى
            الوظيفي
          </td>
          <td>z</td>
          <td>
            المسمى
            الوظيفي
          </td>
          <td>z</td>
        </tr>
        <tr>
          <td>فئة العضوية</td>
          <td>1</td>
          <td>فئة العضوية</td>
          <td>1</td>
        </tr>
        <tr>
          <td>فئة العضوية</td>
          <td>1</td>
          <td>رقم العضوية</td>
          <td />
        </tr>
        <tr>
          <td>التوقيع</td>
          <td>ء</td>
          <td>التوقيع</td>
          <td>ء</td>
        </tr> -->
      </tbody>
    </table>
    <!--هذا التقرير تم مراجعته واعتماده من قبل المقيم المعتمد-->
    <table class="table-fixed">
      <thead style="background:#A7181C;">
        <tr>
          <th colspan="4">
            <div class="header ">
              <div class="header-text">
                هذا التقرير تم مراجعته واعتماده من قبل المقيم المعتمد
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            الاسم
          </td>
          <td>{{ data.facility.resident_name || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            رقم الترخيص
          </td>
          <td>{{ data.facility.license_number || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            فرع التقييم
          </td>
          <td>{{ data.facility.evaluation_branch || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            فئة العضوية
          </td>
          <td>{{ data.facility.membership_category || '' }}</td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            صفته
          </td>
          <td>{{ data.facility.resident_adjective || '' }}</td>
          <td
            class="field"
            :style="styleSubData"
          >
            التوقيع
          </td>
          <td />
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            تاريخ التقرير
          </td>
          <td />
          <td
            class="field"
            :style="styleSubData"
          >
            ختم المنشأة
          </td>
          <td />
        </tr>
      </tbody>
    </table>
    <!--إحداثيات الموقع-->
    <div
      class="html2pdf__page-break"
      :data-number="['6','من',totalPages].join(' ')"
    />
    <table class="first">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                إحداثيات الموقع
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="has-fields">
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            الموقع
          </td>
        </tr>
        <tr>
          <td>
            <gmap-map
              ref="vPlusMap"
              map-type-id="terrain"
              style="width: 100%; height: 400px"
              :center="{lat: +data.latitude || 23, lng: +data.longitude || 24}"
              :zoom="7"
            >
              <GmapMarker
                :position="{lat: +data.latitude || 22, lng: +data.longitude || 22}"
                :icon="{ url: require('../../../assets/marker-red.png'),size: {width: 60, height: 90, f: 'px', b: 'px',},
                         scaledSize: {width: 30, height: 30, f: 'px', b: 'px',},}"
              />
            </gmap-map>
          </td>
        </tr>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            المخطط
          </td>
        </tr>
        <tr>
          <td>
            <img
              :src="data.attached_file.original_url"
              style="height:500px;width:100%;object-fit:cover;"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!--المالحق والصور الفوتوغرافية-->

    <div
      class="html2pdf__page-break"
      :data-number="['7','من',totalPages].join(' ')"
    />
    <table class="first">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                الصور الفوتوغرافية
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="images">
        <img
          v-for="(img, index) in data.images"
          :key="index"
          :src="img.image"
          width="352px"
          height="240px"
          class="image-transaction"
          crossorigin
          style="object-fit:none;object-position: right bottom;"
        >
      </tbody>
    </table>

    <div
      class="html2pdf__page-break"
      :data-number="['7','من',totalPages].join(' ')"
    />
    <table class="first">
      <thead :style="styleData">
        <tr>
          <th>
            <div class="header ">
              <div class="header-text">
                الملاحق
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            الصك
          </td>
        </tr>
      </tbody>
    </table>
    <template v-for="(image,index) in data.instrument_files">
      <div
        :key="index"
        :class="index !== 0 ? 'first' : ''"
        style="height:950px;background:red;"
      >
        <img
          :src="image.original_url"
          style="min-height:950px;width:100%;"
        >
      </div>
      <div
        :key="index"
        class="html2pdf__page-break"
        :data-number="[+'7' + index + 1,'من',totalPages].join(' ')"
      />
    </template>

    <table class="first">
      <tbody>
        <tr>
          <td
            class="field"
            :style="styleSubData"
          >
            خطاب التكليف
          </td>
        </tr>
      </tbody>
    </table>

    <template v-for="(image,index) in data.assignment_letter_files">
      <div
        :key="index"
        :class="index !== 0 ? 'first' : ''"
        style="height:950px;background:red;"
      >
        <img
          :src="image.original_url"
          style="min-height:950px;width:100%;object-fit:cover;"
        >
      </div>
      <div
        :key="index"
        class="html2pdf__page-break"
        :data-number="[+'7' + index + 1,'من',totalPages].join(' ')"
      />
    </template>
    <!-- {{ data.images }} -->
    <!-- الصك -->
    <!-- <div
      v-show="data.instrument_file"
      class="last"
    >
      <img :src="data.imageBase + data.instrument_file">
    </div>
    <div
      v-show="data.instrument_file"
      class="html2pdf__page-break"
    /> -->
    <!-- المخطط -->
    <!-- <div
      v-show="data.attached_file"
      class="first"
    >
      <img :src="data.imageBase +data.attached_file">
    </div>
    <div
      v-show="data.attached_file"
      class="html2pdf__page-break"
    /> -->
    <!-- خطاب التكليف -->
    <!-- <div
      v-show="data.assignment_letter_file"
      class="first"
    >
      <img :src="data.imageBase + data.assignment_letter_file">
    </div>
    <div
      v-show="data.assignment_letter_file"
      class="html2pdf__page-break"
    /> -->

    <!--القيود على االستخدام والنشر-->
    <div
      class="html2pdf__page-break"
      :data-number="['8','من',totalPages].join(' ')"
    />

    <!-- نطاق البحث ومصادر معلومات المقيم -->
    <table class="first">
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                نطاق البحث ومصادر معلومات المقيم
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p
              class="c-empty"
              style="min-height:60px;"
            >
              {{ data.scope_research_sources_information }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                القيود على االستخدام والنشر
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p style="min-height:150px;">
              {{ data.trans_restrictions_publication || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!--إخالء المسؤولية-->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                إخلاء المسؤولية
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p style="min-height:90px;">
              {{ data.trans_evacuation_responsibility || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!--الافتراضات والافتراضات الخاصة (إن وجدت)-->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="6">
            <div class="header ">
              <div class="header-text">
                الافتراضات والافتراضات الخاصة (إن وجدت)
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <p style="min-height:150px;">
              {{ data.trans_special_assumptions || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- عملة التقييم -->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="4">
            <div class="header">
              <div class="header-text">
                عملة التقييم
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            ريال سعودي
          </td>
        </tr>
      </tbody>
    </table>
    <!-- المعايير المهنية -->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="4">
            <div class="header">
              <div class="header-text">
                المعايير المهنية
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            colspan="4"
          >
            <p
              class="not-empty"
              style="min-height:123px;"
            >
              {{ data.trans_professional_standard || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- اقرار باالستقاللية وعدم تضارب المصالح -->
    <table>
      <thead :style="styleData">
        <tr>
          <th colspan="4">
            <div class="header">
              <div class="header-text">
                اقرار بالاستقلالية وعدم تضارب المصالح
              </div>
              <div class="header-icon">
                <v-icon>
                  far fa-file-pdf
                </v-icon>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            colspan="4"
          >
            <p
              class="not-empty"
              style="min-height:50px;"
            >
              {{ data.acknowledgment_independence || '' }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  // import defaultValues from './defaultValuesForPdf.js'

  export default {
    name: 'PdfContent',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      totalPages: 10,
    }),
    computed: {
      styleData () {
        return ({
          'background-color': this.data.customer.cs_data_background_color,
          color: this.data.customer.cs_data_fount_color,
          'border-color': this.data.customer.cs_data_frame_color,
        })
      },
      styleSubData () {
        return ({
          'background-color': this.data.customer.cs_subdata_background_color,
          color: this.data.customer.cs_subdata_fount_color,
          'border-color': this.data.customer.cs_subdata_frame_color,
        })
      },
      cm_space_total () {
        let totalSpace = 0

        if (this.data.transactions_buildings) {
          this.data.transactions_buildings.forEach(b => {
            if (b.building_type !== 'الأرض' && b.building_type !== 'الأسوار') {
              totalSpace += +b.space
            }
          })
        }

        return totalSpace
        // this.data.cm_space_price_average = totalPriceSpace / counterTotalPriceSpace
      },
      cm_space_price_average () {
        let totalPriceSpace = 0
        let counterTotalPriceSpace = 0

        if (this.data.transactions_buildings) {
          this.data.transactions_buildings.forEach(b => {
            if (+b.price !== 0) {
              counterTotalPriceSpace++
              totalPriceSpace += +b.price
            }
          })
        }
        return totalPriceSpace / counterTotalPriceSpace
      },
    },
    methods: {
      join: function (array) {
        return array.filter(item => typeof item !== 'undefined').join(', ')
      },
      addPercentage: function (number) {
        if (number) {
          return number + '%'
        }

        return ''
      },
      formatCurrency: function (number) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',

        })

        if (number) {
          return formatter.format(+number).replace('$', '')
        }

        return ''
      },
      formatDate: function (date) {
        if (date) {
          return new Date(date).toLocaleDateString('en-GB').split('/').reverse().join('-')
        } else {
          return ''
        }
      },
      priceFromMeter: function (price, space) {
        if (!price || !space) {
          return ''
        } else {
          return this.formatCurrency(space / price)
        }
      },
    },
  }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&display=swap');
#pdf-content {
  width: 90%;
  margin: 30px auto;
}
.first {
  margin-top: 30px;
}
.last {
  margin-bottom: 30px;
}
.collapse {
  visibility: collapse;
}
.container-header {
  width:97%;
  margin: auto;
  direction: rtl;
  text-align: right;
}
.container-header h1 {
  font-size: 28px;
}
.container-header .color {
  color: #187F7B;
}
.container-header .sub-header {
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:10px;
  margin-bottom:10px;
}
.container-header .sub-header h2 {
  font-size: 20px;
}
.container-header .sub-header h3 {
  font-size: 16px;
  /* color: #57585A; */
}

.image-facilty img{
  width: 200px;
  height:100px;
  object-fit: cover;
}
.not-empty {
  min-height: 60px;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #57585A;
  margin-bottom: 30px;
}
*:not(.v-icon){
    font-family: 'Baloo Bhaijaan 2', cursive;
  font-weight: normal;

}
table {
  width:100%;
  direction: rtl;
}
table.table-fixed {
  table-layout: fixed;
}
table, th, td, .td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}
table:not(:first-child), table:not(:first-child) th {
  border-top: unset;
}
.colspan td {
  padding: 3px;
}
td,.td{
  padding: 7px 5px;
}
tbody td {
  text-align: center;
}
table thead, .thead {
  background: #187F7B;
  color: #fff;
}
table thead.diff {
  background: #57585A;
  color: #fff;
}
table thead .header {
  display: flex;
  justify-content: space-between;
  padding: 8px 15px;
}
table thead th {
  text-align: right;
  font-size:14px;
}
table tbody td ,.td{
  font-size: 12px !important;
}
table tbody td.small {
  font-size: 10px !important;
}
.td {
  text-align: center;
}
table tbody.has-fields tr td.field {
  background: #F3F5F5;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.text-align-center .v-input--checkbox {
  display: inline-block !important;
}

.checkbox-label {
  color: #333 !important;
}

tbody.images {
  display: grid;
  grid-template-columns: 352px 352px;
justify-content:space-between;
  grid-row-gap: 2px;
}
.image-transaction {
  width: 352px !important;
  height:240px !important;
  object-fit: contain;
}
.html2pdf__page-break {
  direction: rtl;
  text-align: center;
}
/* .html2pdf__page-break::before {
  direction: rtl;
  content: attr(data-number);
  text-align: right;
} */
</style>
